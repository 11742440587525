<template>
	<div id="articleEdit" class="el-content">
        <a-form ref="formRef" :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
            <a-form-item label="资讯标题" required>
                <a-input v-model:value="form.title"></a-input>
            </a-form-item>
            <a-form-item label="资讯副标题" required>
                <a-input v-model:value="form.summary"></a-input>
            </a-form-item>
            <a-form-item label="资讯分类" required>
                <a-select v-model:value="form.category_id">
                    <a-select-option
                        v-for="item in typeList"
                        :key="item.id"
                        :value="item.id">{{item.category_name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="封面(200*200)">
                <kd-img-select :src="form.cover" @change="(url)=>{ form.cover = url }"></kd-img-select>
            </a-form-item>
            <a-form-item label="封面展示位置" >
                <a-radio-group v-model:value="form.mode">
                    <a-radio :value="1">标题右边</a-radio>
                    <a-radio :value="0">标题下边</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="微信公众号文章地址">
                <a-input v-model:value="form.links"></a-input>
                <div class="f12 lh16">填写此项内容后，点击资讯详情将会展示微信公众号的文章内容，详情设置则不会生效，如不需要链接到微信公众号文章可不填写此项</div>
            </a-form-item>
            <a-form-item label="资讯类型" >
                <a-radio-group v-model:value="form.is_video">
                    <a-radio :value="0">图文</a-radio>
                    <a-radio :value="1">视频</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="商品视频展示"  v-if="form.is_video==1">
                <com-select-video v-model="form.video_src"></com-select-video>
            </a-form-item>
            <a-form-item label="虚拟浏览量" >
                <a-input v-model:value="form.view_count"></a-input>
            </a-form-item>
            <a-form-item label="虚拟收藏量" >
                <a-input v-model:value="form.collect_count"></a-input>
            </a-form-item>
            <a-form-item label="详情">
                <tinymce-editor v-model="form.content"></tinymce-editor>
            </a-form-item>
            <a-form-item label="排序" >
                <a-input v-model:value="form.rank"></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 3 }">
                <a-space>
                    <a-button type="primary" @click="saveArticle">保存</a-button>
                    <a-button @click="$router.go(-1)">返回</a-button>
                </a-space>
            </a-form-item>
        </a-form>
	</div>
</template>

<script>
import comSelectVideo from '@/components/public/com-select-video.vue'
import { reactive, ref, toRefs } from 'vue'
import artcileModel from '@/api/article.js'
import { useRoute } from 'vue-router'
export default{
    components:{
        comSelectVideo
    },
    setup(){
        const _d = reactive({
            form:{
                id:0,
                mode:1,
                rank:99,
                title:'',
                cover:'',
                content:'',
                is_video:0,
                video_src:'',
                view_count:0,
                category_id:'',
                collect_count:0,
                summary:'',
                links:'',
            },
            typeList:[],
        })

        const ops = useRoute().query
        artcileModel.getArticleType(1,99,res=>{
            _d.typeList = res.list
        })

        if( ops.id ){
            artcileModel.getArticleDetail(ops.id,Object.keys(_d.form),res=>{
                _d.form = res
            })
        }

        const saveArticle = ()=> artcileModel.addOrEditArticle(_d.form)

        return{
            ...toRefs(_d),
            saveArticle
        }

    }
}
</script>

<style>

</style>
